import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './imgs/logo-nfts.png';
import ConnectButton from './components/ConnectButton';


export default function MintHeader() {
const [burgerMenuOpen, setBurgerMenuOpen] = useState('d-none');
const burgerIconRef = useRef(null);

const toggleBurgerMenu = (e) => {
  if (burgerMenuOpen === 'd-block') {
    burgerIconRef.current.classList.remove('open');
    setBurgerMenuOpen('d-none');
  } else {
    burgerIconRef.current.classList.add('open');
    setBurgerMenuOpen('d-block');
  }
};


  return (
    <div className="container">
      <div className="bsky-topnav nfts">
      <div className="bsky-logo">
      <NavLink to="/"><img src={logo} alt="blocksky" /></NavLink>
      </div>
        <div className="bsky-menu">
          <ul>
            <li><NavLink to='/soon' onClick={() => setBurgerMenuOpen(false)}>Share-to-Earn</NavLink></li>
            <li><NavLink to='/soon' onClick={() => setBurgerMenuOpen(false)}>Staking</NavLink></li>
            <li><NavLink to='/token' onClick={() => setBurgerMenuOpen(false)}>Token</NavLink></li>
            <li><a href="https://www.blocksky.io/Blocksky-Whitepaper.pdf" target="_blank" rel="noopener noreferrer">Whitepaper</a></li>
            <ConnectButton />
          </ul>
        </div>
        <div className="burger-icon" ref={burgerIconRef} onClick={toggleBurgerMenu}>
          <div className="burger-line burger-line-1" />
          <div className="burger-line burger-line-2" />
          <div className="burger-line burger-line-3" />
        </div>
        <div className={`burger-menu ${burgerMenuOpen}`}>
          <ul>
            <li><NavLink to='/soon' onClick={() => setBurgerMenuOpen(false)}>Share-to-Earn</NavLink></li>
            <li><NavLink to='/soon' onClick={() => setBurgerMenuOpen(false)}>Staking</NavLink></li>
            <li><NavLink to='/token' onClick={() => setBurgerMenuOpen(false)}>Token</NavLink></li>
            <li><a href="https://www.blocksky.io/Blocksky-Whitepaper.pdf" target="_blank" rel="noopener noreferrer">Whitepaper</a></li>
            <ConnectButton />
          </ul>
        </div>
      </div>
    </div>
  )
}
